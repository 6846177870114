var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-popup",
    {
      attrs: { fullscreen: "", title: _vm.$t("foto"), active: _vm.visible },
      on: {
        close: _vm.closed,
        "update:active": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "flex items-center w-full justify-center" }, [
        _c("img", { attrs: { src: _vm.src } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
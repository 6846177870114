<template>
  <vs-popup fullscreen @close="closed" :title="$t('foto')" :active.sync="visible">
    <div class="flex items-center w-full justify-center">
      <img :src="src"/>
    </div>
  </vs-popup>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({

  }),

  methods: {
    closed(event) {
      this.$emit('closed', event)
    }
  }

}
</script>
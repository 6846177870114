<template>
  <div>
  <div class="mb-4">
    <div>
      <vs-button
        type="border"
        @click="$router.back()"
        icon="undo"
      >{{ $t('common.back') }}</vs-button>
    </div>
  </div>
  <vx-card>
    <div class="flex flex-col md:flex-row gap-x-4">
      <div class="flex-grow contentzone">

        <div class="relative">
          <Player
            v-if="options && isProctoringRecord(classroomUser.record_medium)"
            :options="options"/>

          <edu-placeholder-action
            v-else
            :label="$t('nao-contem-video')"
            :noAction="true"
            class="z-50 bottom-0 relative p-6"/>
        </div>

        <div class="pt-4" v-permission="'proctoring.audit.add_comment'">
          <vs-button v-if="!showComment" @click="showComment = true">
            {{ $t('adicionar-comentario') }}
          </vs-button>
          <div class="" v-if="showComment">

            <ComplexEditor
              v-bind:editor_data.sync="commentData"
              :placeholder="$t('informe-um-comentario-aqui')"
              class="break-words overflow-y-auto overflow-x-hidden"
              style="max-height:200px;max-width:700px;"
            ></ComplexEditor>
            <div class="flex justify-center items-end flex-wrap gap-2">
              <vs-select
                class="float-right"
                :label="$t('criticidade')"
                v-model="commentSeverity"
              >
                <vs-select-item value="L" text="Baixa"/>
                <vs-select-item value="M" text="Média"/>
                <vs-select-item value="H" text="Alta"/>
              </vs-select>
              <vs-button
                class="float-right mt-2"
                @click="addComment()"
                >{{ $t('action.save') }}</vs-button
              >
              <vs-button
                type="border"
                class="float-right mt-2"
                @click="showComment = false"
                >{{ $t('action.cancel') }}</vs-button
              >
            </div>
          </div>
        </div>
      </div>

      <vs-divider class="md:hidden block mt-8 mb-2"/>

      <div class="w-full md:w-4/12 lg:w-4/12 2xl:w-1/5">
        <vs-card class="shadow-drop">
          <div class="grid gap-x-1 grid-rows-3 auto-cols-min">
            <img @click="showingPhoto = true" :src="proctoringPhoto" class="row-span-3 col-span-1 cursor-pointer" width="50px;" height="50px;"/>
            <label
              class="col-start-2 row-span-1 text-sm font-bold self-end w-64 sm:w-auto md:w-48 lg:w-64 truncate"
              v-tooltip.left="{
                content: userName,
                delay: { show: 100, hide: 100 }
              }"
            >
              {{ userName }}
            </label>

            <label
              class="col-start-2 row-span-1 self-start w-64 sm:w-auto md:w-48 lg:w-64 truncate"
              v-tooltip.left="{
                content: userEmail,
                delay: { show: 100, hide: 100 }
              }"
            >
              {{ userEmail }}
            </label>
          </div>

          <div class="grid grid-cols-2">
            <label class="col-span-2">
              {{ $t('questionario') }}:
              <label class="font-bold">{{ classroomUser && classroomUser.classroom.config.questionnaire.name }}</label>
            </label>
            <label v-if="classroomUser && classroomUser.questionnaire_answer" class="col-span-2 md:col-span-1">
              {{ $t('inicio') }}:
              <label class="font-bold">{{ classroomUser.questionnaire_answer.display_created_at }}</label>
            </label>
            <label v-if="classroomUser && classroomUser.questionnaire_answer" class="col-span-2 md:col-span-1">
              {{ $t('fim') }}:
              <label class="font-bold">{{ $utils.format.date(classroomUser.questionnaire_answer.finished_at) }}</label>
            </label>
          </div>

        </vs-card>
        <vs-tabs v-if="events.length > 0">
          <vs-tab
            :label="$t('eventos')"
            class="overflow-x-hidden overflow-y-auto"
            style="height: 470px;"
          >
            <edu-placeholder-action v-if="noEvents" :label="$t('ainda-nao-ha-eventos')" @action="reload"/>
            <proctoring-classroom-event-list
              :events="events"
              :showFilter="true"
              :showSeverityFilter="true"
            />
          </vs-tab>
        </vs-tabs>
        <edu-placeholder-action v-else style="height:264px;" :noAction="true" :label="$t('nenhum-evento-foi-registrado')"/>
      </div>
    </div>
    <proctoring-image-modal @closed="showingPhoto=false" :src="proctoringPhoto" :visible="showingPhoto"/>
  </vx-card>
  </div>
</template>

<script>
import ProctoringClassroomEventList from './components/ProctoringClassroomEventList.vue'
import ProctoringService from '@/services/api/ProctoringService'
import Player from '../proctor/Player.vue'
import ComplexEditor from '../questionnaires/elements/ComplexEditor.vue'
import EduPlaceholderAction from '@/components/EduPlaceholderAction.vue'
import ProctoringImageModal from './components/ProctoringImageModal.vue'

export default {
  components: {
    ProctoringClassroomEventList,
    Player,
    ComplexEditor,
    EduPlaceholderAction,
    ProctoringImageModal
  },

  props: {
    id: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    service: null,
    events: [],
    classroomUser: null,
    commentData: '',
    commentSeverity: 'L',
    contentSize: 0,
    showComment: false,
    showingPhoto: false,
    streamSource: null,
    options: null
  }),

  created() {
    this.service = ProctoringService.build(this.$vs)
    if (this.id) this.reload()
  },

  computed: {
    noEvents() {
      return this.events.length === 0
    },
    userToken() {
      return this.$store.getters.userToken
    },
    userName() {
      return this.classroomUser ? this.classroomUser.user.name : ''
    },
    userEmail() {
      return this.classroomUser ? this.classroomUser.user.email : ''
    },
    proctoringPhoto() {
      return this.classroomUser && this.classroomUser.proctoringPhoto
    }
  },

  methods: {

    reload() {
      this.fetchData(this.id)
    },

    fetchData(classroomUserId) {
      const token = this.userToken
      this.$vs.loading()
      this.service.loadClassroomUser(classroomUserId).then(
        (data) => {
          this.$vs.loading.close()
          const {events, ...classroomUser} = data[0]

          events.sort((a, b) => {
            const dateA = new Date(a.data.emitted_at)
            const dateB = new Date(b.data.emitted_at)
            if (dateA > dateB) return 1
            if (dateA < dateB) return -1
            return 0
          })

          this.events = events
          this.classroomUser = classroomUser
          const answer = this.classroomUser.answer
          const contentQuestionnaireId = answer.questionnaire_id

          const video_progress = data[0].video_progress
          const fileExt = 'webm'
          const videoPreviewSufix = video_progress >= 0 && video_progress < 100 ? 'pv' : 'p'
          this.options = {
            src: `${process.env.VUE_APP_API_PROCTORING_BASE_URL}/${videoPreviewSufix}/${token}/${contentQuestionnaireId}/${answer.id}/${fileExt}`,
            type: `video/${fileExt}`,
            video_progress,
            autoplay: false,
            controls: true,
            events: this.events
          }
        },
        (error) => {
          this.$vs.loading.close()
          console.error(error)
        }
      )
    },

    addComment() {
      this.$vs.loading()

      const data = ({
        'type': 'comment',
        'data': {text: this.commentData},
        'severity': this.commentSeverity
      })

      this.service.addNewEvent(this.classroomUser.id, data).then((data) => {
        this.showComment = false
        this.commentData = ''
        this.events.unshift(data)
        this.$vs.loading.close()
        this.notifySuccess(this.$vs, this.$t('comentario-adicionado-com-sucesso'))
      })
    },
  }
}
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "mb-4" }, [
        _c(
          "div",
          [
            _c(
              "vs-button",
              {
                attrs: { type: "border", icon: "undo" },
                on: {
                  click: function ($event) {
                    return _vm.$router.back()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("common.back")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "vx-card",
        [
          _c(
            "div",
            { staticClass: "flex flex-col md:flex-row gap-x-4" },
            [
              _c("div", { staticClass: "flex-grow contentzone" }, [
                _c(
                  "div",
                  { staticClass: "relative" },
                  [
                    _vm.options &&
                    _vm.isProctoringRecord(_vm.classroomUser.record_medium)
                      ? _c("Player", { attrs: { options: _vm.options } })
                      : _c("edu-placeholder-action", {
                          staticClass: "z-50 bottom-0 relative p-6",
                          attrs: {
                            label: _vm.$t("nao-contem-video"),
                            noAction: true,
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "proctoring.audit.add_comment",
                        expression: "'proctoring.audit.add_comment'",
                      },
                    ],
                    staticClass: "pt-4",
                  },
                  [
                    !_vm.showComment
                      ? _c(
                          "vs-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.showComment = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("adicionar-comentario")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showComment
                      ? _c(
                          "div",
                          {},
                          [
                            _c("ComplexEditor", {
                              staticClass:
                                "break-words overflow-y-auto overflow-x-hidden",
                              staticStyle: {
                                "max-height": "200px",
                                "max-width": "700px",
                              },
                              attrs: {
                                editor_data: _vm.commentData,
                                placeholder: _vm.$t(
                                  "informe-um-comentario-aqui"
                                ),
                              },
                              on: {
                                "update:editor_data": function ($event) {
                                  _vm.commentData = $event
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-center items-end flex-wrap gap-2",
                              },
                              [
                                _c(
                                  "vs-select",
                                  {
                                    staticClass: "float-right",
                                    attrs: { label: _vm.$t("criticidade") },
                                    model: {
                                      value: _vm.commentSeverity,
                                      callback: function ($$v) {
                                        _vm.commentSeverity = $$v
                                      },
                                      expression: "commentSeverity",
                                    },
                                  },
                                  [
                                    _c("vs-select-item", {
                                      attrs: { value: "L", text: "Baixa" },
                                    }),
                                    _c("vs-select-item", {
                                      attrs: { value: "M", text: "Média" },
                                    }),
                                    _c("vs-select-item", {
                                      attrs: { value: "H", text: "Alta" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "float-right mt-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addComment()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("action.save")))]
                                ),
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "float-right mt-2",
                                    attrs: { type: "border" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showComment = false
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("action.cancel")))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("vs-divider", { staticClass: "md:hidden block mt-8 mb-2" }),
              _c(
                "div",
                { staticClass: "w-full md:w-4/12 lg:w-4/12 2xl:w-1/5" },
                [
                  _c("vs-card", { staticClass: "shadow-drop" }, [
                    _c(
                      "div",
                      { staticClass: "grid gap-x-1 grid-rows-3 auto-cols-min" },
                      [
                        _c("img", {
                          staticClass: "row-span-3 col-span-1 cursor-pointer",
                          attrs: {
                            src: _vm.proctoringPhoto,
                            width: "50px;",
                            height: "50px;",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showingPhoto = true
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.left",
                                value: {
                                  content: _vm.userName,
                                  delay: { show: 100, hide: 100 },
                                },
                                expression:
                                  "{\n              content: userName,\n              delay: { show: 100, hide: 100 }\n            }",
                                modifiers: { left: true },
                              },
                            ],
                            staticClass:
                              "col-start-2 row-span-1 text-sm font-bold self-end w-64 sm:w-auto md:w-48 lg:w-64 truncate",
                          },
                          [_vm._v(" " + _vm._s(_vm.userName) + " ")]
                        ),
                        _c(
                          "label",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.left",
                                value: {
                                  content: _vm.userEmail,
                                  delay: { show: 100, hide: 100 },
                                },
                                expression:
                                  "{\n              content: userEmail,\n              delay: { show: 100, hide: 100 }\n            }",
                                modifiers: { left: true },
                              },
                            ],
                            staticClass:
                              "col-start-2 row-span-1 self-start w-64 sm:w-auto md:w-48 lg:w-64 truncate",
                          },
                          [_vm._v(" " + _vm._s(_vm.userEmail) + " ")]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "grid grid-cols-2" }, [
                      _c("label", { staticClass: "col-span-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("questionario")) + ": "),
                        _c("label", { staticClass: "font-bold" }, [
                          _vm._v(
                            _vm._s(
                              _vm.classroomUser &&
                                _vm.classroomUser.classroom.config.questionnaire
                                  .name
                            )
                          ),
                        ]),
                      ]),
                      _vm.classroomUser &&
                      _vm.classroomUser.questionnaire_answer
                        ? _c(
                            "label",
                            { staticClass: "col-span-2 md:col-span-1" },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("inicio")) + ": "),
                              _c("label", { staticClass: "font-bold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.classroomUser.questionnaire_answer
                                      .display_created_at
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.classroomUser &&
                      _vm.classroomUser.questionnaire_answer
                        ? _c(
                            "label",
                            { staticClass: "col-span-2 md:col-span-1" },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("fim")) + ": "),
                              _c("label", { staticClass: "font-bold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$utils.format.date(
                                      _vm.classroomUser.questionnaire_answer
                                        .finished_at
                                    )
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm.events.length > 0
                    ? _c(
                        "vs-tabs",
                        [
                          _c(
                            "vs-tab",
                            {
                              staticClass: "overflow-x-hidden overflow-y-auto",
                              staticStyle: { height: "470px" },
                              attrs: { label: _vm.$t("eventos") },
                            },
                            [
                              _vm.noEvents
                                ? _c("edu-placeholder-action", {
                                    attrs: {
                                      label: _vm.$t("ainda-nao-ha-eventos"),
                                    },
                                    on: { action: _vm.reload },
                                  })
                                : _vm._e(),
                              _c("proctoring-classroom-event-list", {
                                attrs: {
                                  events: _vm.events,
                                  showFilter: true,
                                  showSeverityFilter: true,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("edu-placeholder-action", {
                        staticStyle: { height: "264px" },
                        attrs: {
                          noAction: true,
                          label: _vm.$t("nenhum-evento-foi-registrado"),
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c("proctoring-image-modal", {
            attrs: { src: _vm.proctoringPhoto, visible: _vm.showingPhoto },
            on: {
              closed: function ($event) {
                _vm.showingPhoto = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }